// @flow
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { StaticQuery, graphql } from 'gatsby'
// import CookieConsentBar from './../CookieConsentBar'

import '../../styles/base.scss'
import baseStyles from './Layout.scss'

type Props = {
  ...StyleProps,
  children?: any,
  footer?: any,
  header?: any
}

const Layout = ({ styles, children, footer, header }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div className={styles.root}>
          {header}
          <div className={styles.content}>
            {children}
          </div>
          {footer}
          {/* <CookieConsentBar /> */}
        </div>
        <div id="outdated"></div>
        <script
          async
          src="https://cdnjs.cloudflare.com/ajax/libs/outdated-browser/1.1.5/outdatedbrowser.min.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                function addLoadEvent(func) {
                  var oldonload = window.onload;
                  if (typeof window.onload != 'function') {
                      window.onload = func;
                  } else {
                      window.onload = function() {
                          if (oldonload) {
                              oldonload();
                          }
                          func();
                      }
                  }
              }
              //call plugin function after DOM ready
              addLoadEvent(function(){
                  outdatedBrowser({
                      bgColor: '#f25648',
                      color: '#ffffff',
                      lowerThan: 'transform',
                      languagePath: 'https://cdn.jsdelivr.net/npm/outdatedbrowser@1.1.5/outdatedbrowser/lang/de.html'
                  })
              });`
          }}
        />
        <noscript><img height='1' width='1' style={{ display: 'none' }} alt='' src='https://px.ads.linkedin.com/collect/?pid=2131026&fmt=gif' /></noscript>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Layout)
